.projects {
  .explore {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 48px;
      line-height: 72px;
      text-transform: uppercase;
      color: #ffffff;
    }
    span {
      color: #BC1823;
    }
    .detail {
      width: 454px;
      max-width: 100%;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 139.5%;
      /* or 33px */

      letter-spacing: -0.02em;

      /* Texts - Paragraphs */

      color: rgba(255, 255, 255, 0.8);
    }
  }

  .card {
    background-color: #000;
    margin-bottom: 25px;

    &:hover {
      box-shadow: inset 0px 0px 12px 1px #ffffff33;
    }

    img {
      width: 100%;
      margin-bottom: 15px;
    }

    .title {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 48px;
      line-height: 72px;
      /* identical to box height */

      text-transform: capitalize;

      /* Texts - Main */

      color: #ffffff;
      margin-bottom: 15px;
    }

    .tech-use {
      list-style: none;
      display: flex;
      gap: 20px;
      align-items: center;
      padding-left: 0;

      li {
        border: 1px solid #fff;
        border-radius: 20px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 30px;
        text-align: center;
        text-transform: uppercase;
        color: #ffffff;
        padding: 3px 20px;

        &:hover {
          background-color: #bc1823;
          cursor: pointer;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .projects {
    .explore {
      display: block;

      .title {
        font-size: 40px;
      }

      .detail {
        font-size: 18px;
      }
    }

    .card {
      margin-bottom: 15px;
      .title {
        font-size: 32px;
        margin-bottom: 10px;
      }

      .tech-use li {
        font-size: 12px;
        padding: 0px 15px;
      }
    }
  }
}
