#About {
  .descrip {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 139.5%;
    position: relative;
    // z-index: 120;
    letter-spacing: -0.02em;
    color: rgba(255, 255, 255, 0.8);
  }

  


  .btn-getstarted {
    width: 249px;
    height: 64px;

    background: #BC1823;
    border-radius: 8px;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.171);
  }

  .btn-getstarted:hover {
    width: 249px;
    height: 64px;
    background: black;
    border-radius: 8px;
    color: #BC1823;
    font-size: 18px;
    font-weight: 600;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.171);
  }

  .img-buttonCircle {
    position: absolute;
    right: 10%;
    top: -80px;
  }
  .imgGroup {
    position: relative;
  }

  .big-title h1{
    font-family: "Poppins";
    font-style: normal;
    overflow: hidden;
    // margin: 0 auto;
    // border-right: .15em solid orange;
    font-weight: 400;
    font-size: 62px;
    line-height: 170%;
    // white-space: nowrap;
    text-transform: capitalize;
    color: #ffffff;
    // animation: typing 3.5s steps(10, end), 
    //             blink-caret .75s step-end infinite;
  }

  // @keyframes typing {
  //   from { width: 0 }
  //   to { width: 100% }
  // }

  // @keyframes blink-caret {
  //   from, to { border-color: transparent }
  //   50% { border-color: #BC1823; }
  // }

  span {
    color: #bc1823;
  }

  .workData2 {
    border-bottom: 1px solid #898989;
    width: 259px;
    max-width: 100%;
    margin-bottom: 20px;
    h1 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 60px;
      line-height: 90px;
      color: #ffffff;
    }
    span {
      color: #bc1823;
    }

    p {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 22px;
      line-height: 33px;
      color: #ffffff;
      margin-bottom: 25px;
    }
  }

  .img-groupDiverse {
    border-radius: 0 30px 30px 0px;
  }
}

@media screen and (max-width: 992px) {
  #About {
    .big-title h1{
      margin-top: -15%;
      margin-left: 1%;
      margin-right: 1%;
      font-size: 30px;
      line-height: 170%;

    }
  }
}

@media screen and (max-width: 600px) {
  #About {
    .big-title {
      font-size: 20px;
    }
    .descrip {
      font-size: 18px;
    }

    .btn-getstarted {
      width: 220px;
      height: 50px;
    }

    .img-buttonCircle {
      top: -40px;
      width: 80px;
    }

    .workData2 {
      margin: 0 auto;
      text-align: center;

      h1 {
        font-size: 40px;
        line-height: 70px;
      }
      p {
        font-size: 18px;
      }
    }
  }
}
