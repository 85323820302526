.contact {
  padding: 60px 0;
  .social {
    margin-top: 30px;

    img {
      transition: transform .7s ease-in-out;
    }
    img:hover {
      transform: rotate(360deg);
      cursor: pointer;
    }
    h3 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 89.5%;
      /* or 21px */

      letter-spacing: -0.03em;

      color: #ffffff;
      margin-bottom: 30px;
    }
    ul {
      padding-left: 0;
      list-style: none;
      display: flex;
      gap: 20px;
      align-items: center;
      li {
        img {
          height: 20px;
        }
      }
    }
  }

  .label {
    // font-family: "Satoshi";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    /* identical to box height */

    color: #BC1823;

    opacity: 0.75;
    margin-bottom: 18px;
  }

  span{
    color: #BC1823;
  }

  .head-title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 66px;
    line-height: 124%;
    /* identical to box height, or 82px */

    color: #ffffff;
    margin-bottom: 60px;
  }

  .img-contact {
    margin-bottom: 60px;
  }

  .email {
    margin-bottom: 60px;

    a {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 160%;
      /* or 38px */
      color: #ffffff;
    }
  }

  .contact-form {
    background: #14161f;
    border: 2px solid #bc1823;
    border-radius: 48px;
    padding: 48px;

    .title {
      //   font-family: "Clash Display";
      font-style: normal;
      font-weight: 400;
      font-size: 40px;
      line-height: 124%;
      /* identical to box height, or 50px */

      color: #ffffff;
      margin-bottom: 50px;
    }

    .input-group {
      display: block;
      margin-bottom: 30px;
      label {
        display: block;
        // font-family: "Satoshi";
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 15px;
        color: #ffffff;
      }
      .input-style {
        width: 100%;
        border-bottom: 0.5px solid #ffffff;
        font-family: "Satoshi";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 160%;
        border: none;
        // identical to box height, or 26px 
        background-color: #14161f;
        color: #ffffff;
        opacity: 0.8;
        outline: none;
        border-bottom: 0.5px solid #ffffff;
      }
      .input-style-number {
        width: 100%;
        border-bottom: 0.5px solid #ffffff;
        font-family: "Satoshi";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 160%;
        border: none;
        /* identical to box height, or 26px */
        outline: none;
        background-color: #14161f;
        color: #ffffff;
        opacity: 0.8;
        border-bottom: 0.5px solid #ffffff;
      }
    }

    input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


    .btn-send {
      padding: 22px 46px 24px;

      width: 138px;
      height: 64px;
      background: #BC1823;
      border-radius: 20px;
      color: #fff;
    }
  }
}

@media (min-width: 992px) {
  .contact {
    .desktop-form {
      display: block;
    }
    .mobile-form {
      display: none;
    }
  }
}

@media (max-width: 991px) {
  .contact {
    padding: 50px 0 15px;
    .desktop-form {
      display: none;
    }
    .mobile-form {
      display: block;
    }

    .head-title {
      font-size: 30px;
      text-align: center;
      margin-bottom: 30px;
      
    }

    .img-contact {
      margin-bottom: 30px;
      color: red;
    }

    .label {
      font-size: 16px;
      text-align: center;
      margin-top: -5%;
    }

    .email a {
      font-size: 20px;
    }

    .email {
      margin-bottom: 40px;
      text-align: center;
    }

    .social ul{
      padding-left: 110px;
      // text-align: center !important;
    }

    .contact-form {
      background: #14161f;
      border: 2px solid #bc1823;
      border-radius: 48px;
      padding: 30px 15px;

      .title {
        margin-bottom: 30px;
        font-size: 35px;
      }

      .input-group label {
        font-size: 16px;
      }

      .btn-send {
        width: 130px;
        height: 50px;
      }
    }
  }
}
