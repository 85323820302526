.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.containerxyz {
  height: 30px;
  width: 30px;
}

 .top-right{
  position: absolute;
  width: 600px;
  height: 600px;
  left: 900px;
  top: -118px;
  border-radius: 0 0 500px 100%;
  background: rgba(255, 170, 170, 0.5);
  filter: blur(152px);
} 

@media (max-width: 992px){
  .top-right{
    position: absolute;
    width: 365px;
    height: 300px;
    left: -10px;
    top: 40px;
    border-radius: 0 0 500px 100%;
    background: rgba(255, 170, 170, 0.5);
    filter: blur(152px);
  } 
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  } 
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
