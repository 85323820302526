.service-style {
  padding-bottom: 100px;
  .head {
    text-align: center;
    margin-bottom: 60px;
    p {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      /* identical to box height */
      margin-top: 3%;
      text-align: center;
      text-transform: uppercase;
    }
    h1 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 48px;
      line-height: 72px;
      /* identical to box height */
      margin-top: 2%;
      text-align: center;
      text-transform: uppercase;

      color: #bc1823;
    }
  }

  .flow-line {
    padding-top: 17px;
    img {
      display: flex;
      align-items: center;
      justify-content: center;
      
    }

    .line {
      margin-left: 3.5px;
      margin-top: 10px;
      margin-bottom: 40px;
    }
    .line.last{
      display: none;
    }
  }
 

  .ser-list {
    display: flex;
    gap: 40px;
    justify-content: space-between;
    .detail-sec {
      width: 550px;
      max-width: 100%;
      margin-top: 1%;
    }
    .img-sec{
      border-radius: 3%;
    }
  }
}

@media screen and (max-width: 767px) {
  .service-style {
    .ser-list {
      display: block;
    }

    .flow-line .line {
      margin-left: 0.5px;
    }

    .head h1{
      font-size: 20px;
      margin-top: 5%;
      margin-bottom: -10%;
      line-height: 30px;
      
    }
  }
}
