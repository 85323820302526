span{
    color: #BC1823;
}

.title-1{
    text-align: center;
    font-size: 60px;
}

.Containt{
    font-size: 20px;
}

.flow-line {
    padding-top: 17px;
    img {
      display: flex;
      align-items: center;
      justify-content: center;
      
    }
}

.detail-sec1 {
  font-size: larger;
    padding-top: 6%;
    font-size: large;
    padding-right: 1%;
    margin-left: -5%;
    // text-align: left;
  }

.ser-list1{
    padding-top: 3%;
    text-align: center;
}

  .line {
    margin-left: 3.5px;
    margin-top: 10px;
    margin-bottom: 40px;
  }
  .line.last{
    display: none;
  }


  @media (max-width: 992px){

    .detail-sec1 {
      font-size: larger;
        padding-top: 6%;
        font-size: large;
        text-align: justify;
        margin-left: 2%;
        margin-right: 2%;
      }
    

  }