.clients {
  padding-bottom: 60px;
  .title {
    // font-family: "Clash Display";
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 70px;
    /* identical to box height, or 146% */

    text-transform: uppercase;
    text-align: center;
    color: #bc1823;
    margin-bottom: 5%;
    margin-top: 2%;
  }

  .curve{
    border-radius: 10px;
    height: 120px;
  }

  .curve2{
    border-radius: 10px;
    height: 80px;
    // width: 70px;
  }
}

@media screen and (max-width: 600px) {
  .clients {
    .title {
      margin-top: -10%;
      font-size: 15px;
    }

  }
}
